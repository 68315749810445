import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { UPDATE_CATEGORY } from "../../service/productApi";
import { BULK_EMAIL, GET_USER_LIST, SEND_BULK_EMAIL } from "../../service/userApi";
import EditCategoryModal from "../category/EditCategoryModal";
import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import debounce from "lodash.debounce";
import { Button, Modal } from "react-bootstrap";

const Customer = () => {
	const [userList, setUserList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [emailDetails, setEmailDetails] = useState({
		subject: "",
		message: "",
	});
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchTerm, setSearchTerm] = useState("");

	// Debounced version of getUserList
	const fetchUserList = useCallback(
		debounce((page, search) => {
			getUserList(page, search);
		}, 300), // Delay in milliseconds
		[]
	);

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedCategory(null);
	};

	const handleSaveChanges = async (updatedCategory) => {
		try {
			const payload = {
				id: updatedCategory.id,
				categoryName: updatedCategory.categoryName,
				description: updatedCategory.description,
			};
			const { data } = await UPDATE_CATEGORY(payload);
			if (data.code === 1) {
				toast.success(data.message);
				getUserList();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const getUserList = async (page, search = "") => {
		try {
			const { data } = await GET_USER_LIST({ page, search });
			setUserList(data.result.userList);
			setTotalPages(data.result.pagination.totalPages);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		}
	};

	useEffect(() => {
		fetchUserList(currentPage, searchTerm);
	}, [currentPage, searchTerm, fetchUserList]);

	const handlePageClick = (event) => {
		const selectedPage = event.selected + 1;
		setCurrentPage(selectedPage);
		fetchUserList(selectedPage, searchTerm);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
		setCurrentPage(1); // Reset to first page on search
		fetchUserList(1, e.target.value); // Trigger the debounced function
	};

	const maskPhoneNumber = (phoneNumber) => {
		if (phoneNumber.length > 6) {
			return `******${phoneNumber.slice(-4)}`;
		}
		return phoneNumber;
	};

	const handleSelectUser = (email) => {
		setSelectedUsers((prev) =>
			prev.includes(email) ? prev.filter((id) => id !== email) : [...prev, email]
		);
	};

	const handleSelectAll = (e) => {
		if (e.target.checked) {
			setSelectedUsers(userList.map((user) => user.email));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleSendBulkEmail = async () => {
		setLoading(true);
		try {
			const payload = {
				emailList: selectedUsers,
				subject: emailDetails.subject,
				body: emailDetails.message,
			};
			await BULK_EMAIL(payload);
			const {data} = await SEND_BULK_EMAIL()
			if (data.code === 1) {
				toast.success("Emails sent successfully!");
				setShowEmailModal(false);
				setSelectedUsers([]);
			} else {
				toast.error(data.message || "Failed to send emails.");
			}
		} catch (error) {
			const {response} = error || {}
			const {data} = response || {}
			const {message} = data || {}
			toast.error(message || "An unexpected error occurred.");
		}finally{
			setLoading(false)
		}
	};

	return (
		<div className="container p-4">
			<div className="mb-3">
				<input
					type="search"
					className="form-control"
					placeholder="Search by first name, last name, email, etc."
					onChange={handleSearchChange}
				/>
			</div>

			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th >
						<input
						type="checkbox"
						onChange={handleSelectAll}
						checked={selectedUsers.length === userList.length && userList.length > 0}
						/>
						</th>
						<th scope="col">#</th>
						<th scope="col">First Name</th>
						<th scope="col">Last Name</th>
						<th scope="col">Mobile No</th>
						<th scope="col">Email</th>
						<th scope="col">Date Of Birth</th>
						<th scope="col">Gender</th>
						
					</tr>
				</thead>
				<tbody>
					{userList.length > 0 ? (
						userList.map((user,index) => (
							<tr key={user.id}>
								<td>
								<input
									type="checkbox"
									checked={selectedUsers.includes(user.email)}
									onChange={() => handleSelectUser(user.email)}
								/>
							</td>
								<td>{(currentPage - 1) * 10 + (index + 1)}</td>
								<td>{user.firstName}</td>
								<td>{user.lastName}</td>
								<td>{maskPhoneNumber(user.mobileNumber)}</td>
								<td>{user.email}</td>
								<td>{moment(user.dob).format("DD-MM-YYYY")}</td>
								<td>{user.gender}</td>
							</tr>
						))
					) : searchTerm ? (
						<tr>
							<td colSpan="6" className="text-center">
								No data found
							</td>
						</tr>
					) : (
						<tr>
							<td colSpan="6" className="text-center">
								Loading...
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{selectedUsers.length > 0 && (
				<Button className="mt-3 ms-auto d-block btn btn-success buttonSuccess" onClick={() => setShowEmailModal(true)}>
					Send Email
				</Button>
			)}

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={handlePageClick}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{/* Render the edit modal */}
			{selectedCategory && (
				<EditCategoryModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					categoryData={selectedCategory}
					handleSave={handleSaveChanges}
				/>
			)}

			<Modal show={showEmailModal} onHide={() => setShowEmailModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Send Bulk Email</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="mb-3">
						<label>Subject</label>
						<input
							type="text"
							className="form-control"
							value={emailDetails.subject}
							onChange={(e) =>
								setEmailDetails({ ...emailDetails, subject: e.target.value })
							}
						/>
					</div>
					<div className="mb-3">
						<label>Message</label>
						<textarea
							className="form-control"
							rows="4"
							value={emailDetails.message}
							onChange={(e) =>
								setEmailDetails({ ...emailDetails, message: e.target.value })
							}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowEmailModal(false)}>
						Cancel
					</Button>
					<Button variant="primary" onClick={handleSendBulkEmail} className="btn btn-success buttonSuccess">
					{loading ? "Sending..." : "Send"}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default Customer;
