import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./CategoryList.scss";

const DeleteCategoryModal = ({ show, handleClose, handleDelete }) => {
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirm Delete</Modal.Title>
			</Modal.Header>
			<Modal.Body>Are you sure you want to delete this category?</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="danger" onClick={handleDelete} className="btnPrimary">
					Delete
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteCategoryModal;
