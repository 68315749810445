import { useState } from "react";
import usePasswordToggle from "./../../hooks/usePasswordToggle";
import React from "react";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import "./adminprofile.scss";
import { CHANGE_PASSWORD } from "../../service/apiAuth";
import { useLocation, useNavigate } from "react-router-dom";

const ChangePassword = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { state } = location || {};
	const { otpId, mobile } = state || {};
	const [loader, setLoader] = useState(false);
	const [inputType2, icon2] = usePasswordToggle();
	const [inputType3, icon3] = usePasswordToggle();
	const [passwords, setPasswords] = useState({
		newPassword: "",
		confirmPassword: "",
	});
	const [errorMessage, setErrorMessage] = useState("");

	const validatePassword = (password) => {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{8,}$/.test(
			password
		);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setPasswords({
			...passwords,
			[name]: value,
		});
		setErrorMessage("");
	};

	const handleSubmit = async (event) => {
		try {
			event.preventDefault();
			setLoading(true);
			const { newPassword, confirmPassword } = passwords;
			let hasError = false;

			if (!newPassword || !confirmPassword) {
				setErrorMessage("Please fill in all fields.");
				hasError = true;
			} else if (newPassword !== confirmPassword) {
				setErrorMessage("New password and confirm password do not match.");
				hasError = true;
			} else if (!validatePassword(newPassword)) {
				setErrorMessage(
					"Password should be at least 8 characters long including 1 uppercase letter, 1 lowercase letter, 1 number, and 1 symbol."
				);
				hasError = true;
			}

			if (hasError) return;

			const response = await CHANGE_PASSWORD({
				confirmPassword: passwords.confirmPassword,
				newPassword: passwords.newPassword,
				otpId: otpId,
				mobile: mobile,
			});
			console.log(response);
			const { data } = response;

			const { code, message } = data;

			if (code === 1) {
				toast.success(message || "Password updated successfully!");
				navigate("/dashboard");
			} else {
				toast.error(message || "An error occurred.");
			}
		} catch (error) {
			const {response} = error || {}
			const {data} = response || {}
			const {message} = data || {}
			toast.error(message || "An unexpected error occurred.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="profile_content mt-3 mb-3">
						<div className="title">
							<h4 className="text-center">Change Password</h4>
						</div>

						<div className="content_inner">
							<form onSubmit={handleSubmit}>
								<div className="form_field">
									<label className="label">New Password</label>
									<div className="password">
										<input
											type={inputType2}
											name="newPassword"
											value={passwords.newPassword}
											onChange={handleInputChange}
											className={`form-control input ${
												errorMessage && "is-invalid"
											}`}
											placeholder="Enter new password"
										/>
										<span className="password_icons">{icon2}</span>
									</div>
								</div>
								<div className="form_field">
									<label className="label">Confirm New Password</label>
									<div className="password">
										<input
											type={inputType3}
											name="confirmPassword"
											value={passwords.confirmPassword}
											onChange={handleInputChange}
											className={`form-control input ${
												errorMessage && "is-invalid"
											}`}
											placeholder="Enter confirm password"
										/>
										<span className="password_icons">{icon3}</span>
									</div>
								</div>
								{errorMessage && (
									<span className="error_message">{errorMessage}</span>
								)}
								<div className="button_wrap text-center mt-4">
									<Button
										className="btn btn-success buttonSuccess"
										style={{ width: "150px" }}
										type="submit"
										disabled={loading}
									>
										{loading ? "Updating..." : "Update"}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassword;
