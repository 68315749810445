import React from "react";
import "./dashboard/dashboard.scss";
import { MdHelpCenter } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import { Link } from "react-router-dom";

const HelpCenter = () => {
	return (
		<div className="page_section">
			<div className="center-container">
				<MdHelpCenter />
				Help Center
				<Link to="/dashboard">
					Go to dashboard <GoArrowRight />
				</Link>
			</div>
		</div>
	);
};

export default HelpCenter;
