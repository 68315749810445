import CryptoJS from "crypto-js";

export const PASSWORD_VALIDATION = (password) => {
	const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).{8,}$/
	return passwordRegex.test(password)
}

const SECRET_KEY = "D#4436Bqe2$%$#$%MC&^$@#^*&%I";

export const encryptdata = (value) => {
	return CryptoJS.AES.encrypt(value, SECRET_KEY).toString();
};
 
export const setToken = (token) => {
	const encryptToken = encryptdata(token);
	return encryptdata(localStorage.setItem("admin_token", encryptToken));
};

export const decryptData = (data) => {
	if(data === null) return null
	const decryptedBytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
	return decryptedBytes.toString(CryptoJS.enc.Utf8);
};
 
export const getToken = () => {
	const encryptToken = localStorage.getItem("admin_token");
	if (!encryptToken) return;
	return decryptData(encryptToken);
 };