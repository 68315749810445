import React, { useState } from "react";
import "./ResetPassword.scss";
import rentteLogo from "./../../../assets/images/rentteLogo.png";
import Button from "@mui/material/Button";
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { RESET_PASSWORD } from "../../../service/apiAuth";
import "bootstrap/dist/css/bootstrap.min.css";
import { Paper } from "@mui/material";

const ResetPassword = () => {
	const navigate = useNavigate();
	const { token } = useParams();
	const [formData, setFormData] = useState({
		newPassword: "",
		confirmPassword: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const resetPassword = async (e) => {
		try {
			e.preventDefault();
			const resetPassword = {
				confirmPassword: formData.newPassword,
				newPassword: formData.newPassword,
				token: token,
			};
			const { data } = await RESET_PASSWORD(resetPassword);
			const { code, message } = data || {};
			if (code === 0) {
				toast.error(message);
			}
			toast.success(message);
			navigate("/");
		} catch (error) {
			const {response} = error || {}
			const {data} = response || {}
			const {message} = data || {}
			toast.error(message || "An unexpected error occurred.");
		}
	};
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	return (
		<div className="reset_section">
			<div className="container">
				<div className="d-flex align-items-center justify-content-center">
					<div className="row justify-content-center">
						<div className="col-md-10 d-flex align-items-center justify-content-center">
							<Paper elevation={4}>
								<form className="form_container">
									<div className="logo">
										<img src={rentteLogo} alt="Rentte Logo" />
									</div>
									<div className="form_heading">
										<h3>Reset Password</h3>
										<h6>Set your password here!</h6>
									</div>
									<div className="form_content">
										<FormControl
											sx={{ mb: 1 }}
											variant="outlined"
											color="success"
											size="small"
											fullWidth
											required
										>
											<InputLabel htmlFor="outlined-new-password">
												New Password
											</InputLabel>
											<OutlinedInput
												id="outlined-new-password"
												type={showPassword ? "text" : "password"}
												name="newPassword"
												onChange={handleInputChange}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															edge="end"
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
												label="New Password"
											/>
										</FormControl>

										<FormControl
											sx={{ mb: 1 }}
											variant="outlined"
											color="success"
											size="small"
											fullWidth
											required
										>
											<InputLabel htmlFor="outlined-confirm-password">
												Confirm Password
											</InputLabel>
											<OutlinedInput
												id="outlined-confirm-password"
												type={showPassword ? "text" : "password"}
												endAdornment={
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={handleClickShowPassword}
															edge="end"
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												}
												label="Confirm Password"
												name="confirmPassword"
												onChange={handleInputChange}
											/>
										</FormControl>

										<div className="btn_container">
											<Button
												variant="contained"
												size="small"
												sx={{ px: 5 }}
												type="submit"
												className="form_button"
												onClick={resetPassword}
											>
												Reset
											</Button>
										</div>
									</div>
								</form>
							</Paper>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
