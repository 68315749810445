import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./CategoryList.scss";

const EditCategoryModal = ({ show, handleClose, categoryData, handleSave }) => {
	const [formData, setFormData] = useState(categoryData);
	const [errors, setErrors] = useState({});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = () => {
		const newErrors = {};

		if (!formData.categoryName.trim()) {
			newErrors.categoryName = "Categort Name is required";
		}

		if (!formData.description.trim()) {
			newErrors.description = "Description is required";
		}

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		handleSave(formData);
		handleClose();
	};

	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Edit Category</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group controlId="formCategoryName">
						<Form.Label>Category Name</Form.Label>
						<Form.Control
							type="text"
							name="categoryName"
							value={formData.categoryName}
							onChange={handleChange}
							isInvalid={!!errors.categoryName}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.categoryName}
						</Form.Control.Feedback>
					</Form.Group>
					<Form.Group controlId="formDescription">
						<Form.Label>Description</Form.Label>
						<Form.Control
							as="textarea"
							name="description"
							rows={3}
							value={formData.description}
							onChange={handleChange}
							isInvalid={!!errors.description}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.description}
						</Form.Control.Feedback>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Close
				</Button>
				<Button variant="primary" onClick={handleSubmit} className="btnPrimary">
					Save Changes
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default EditCategoryModal;
