import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./login/Login";
import ForgotPassword from "./forgotPassword/ForgotPassword";
import "./auth.css";
import { Paper } from "@mui/material";

const Authentication = () => {
	const [step, setSteps] = useState(1);

	return (
		<div className="auth_section">
			<div className="container">
				<div className="auth_container">
					<div
						className="row justify-content-center"
						style={{ width: "680px" }}
					>
						<div className="col-md-10">
							<Paper elevation={4}>
								<div className="row g-0">
									<div className="col-md-12 mb-5">
										<div className="right_container">
											{step === 1 && (
												<Login forgotPassword={() => setSteps(2)} />
											)}
											{step === 2 && (
												<ForgotPassword backToLogin={() => setSteps(1)} />
											)}
										</div>
									</div>
								</div>
							</Paper>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Authentication;
