import axios from "axios";
import { getToken } from "../util/validator";
export const userServiceApiCall = async (
  method = "",
  url = "",
  data = {},
  isMultipart = false,
) => {
  let axiosConfig = {
    method,
    url: `${process.env.REACT_APP_USER_SERVICE_API_CALL}${url}`,
    headers: {
      Authorization: getToken() ? getToken () : null,
    },
  };

  if (isMultipart) {
    axiosConfig.headers["Content-Type"] = "multipart/form-data";
  }
  if (method !== "get") {
    if (isMultipart) {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      axiosConfig["data"] = formData;
    } else {
      axiosConfig["data"] = data;
    }
  }

  try {
    
    let response = await axios(axiosConfig);
    return response;
  } catch (error) {
    throw error
  }
};
