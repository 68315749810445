import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./dashboard.scss";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { Bar, Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { CDBContainer } from "cdbreact";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PieChart } from "@mui/x-charts/PieChart";

// Register the necessary components with Chart.js
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	BarElement,
	Title,
	Tooltip,
	Legend
);

function createData(product, category) {
	return { product, category };
}

const rows = [
	createData("Bike", "Automobiles"),
	createData("Chair", "Furniture"),
	createData("3BHK Flat", "Real Estate"),
	createData("Hiking Bag", "Travel Kits"),
	createData("Basketball", "Sports"),
	createData("Gaming Console", "Sports"),
];

const palette = ["#0C6C6A", "#e1671c", "#f3bb01"];

const Dashboard = () => {
	const [data] = useState({
		labels: ["January", "February", "March", "April", "May", "June", "July"],
		datasets: [
			{
				label: "My First dataset",
				backgroundColor: "#da8149",
				borderColor: "#da8149",
				data: [65, 59, 90, 81, 56, 55, 40],
			},
			{
				label: "My Second dataset",
				backgroundColor: "#0c6c6a",
				borderColor: "#0c6c6a",
				data: [28, 48, 40, 19, 96, 27, 100],
			},
		],
	});

	return (
		<div className="dashboard_page p-2 mt-1" id="dashboard-content-container">
			<div className="container mb-3">
				<div className="row mt-3">
					<div className="col-md-12 col-lg-6 col-sm-10 mb-4 mb-lg-0">
						<div className="card h-100">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center">
									<h5 className="card-title">Total Products Taken on Rent</h5>
									<p className="card_number">70k</p>
								</div>
								{/* <h6 className="card-subtitle mb-2 text-muted">
										Last 1 month
									</h6> */}
								<div className="card-text">
									<div className="revenue_graph">
										<CDBContainer>
											<Bar data={data} options={{ responsive: true }} />
										</CDBContainer>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6 col-sm-10">
						<div className="card h-100">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center">
									<h5 className="card-title">Total Products Given on Rent</h5>
									<p className="card_number">80k</p>
								</div>
								{/* <h6 className="card-subtitle mb-2 text-muted">
										Last 1 month
									</h6> */}
								<div className="card-text">
									<div className="revenue_graph">
										<CDBContainer>
											<Bar data={data} options={{ responsive: true }} />
										</CDBContainer>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6 col-sm-10 mt-4">
						<div className="card">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center">
									<h5 className="card-title">Total Revenue</h5>
									<p className="card_number">$340k</p>
								</div>
								{/* <h6 className="card-subtitle mb-2 text-muted">Last 7 Days</h6> */}
								<div className="card-text">
									<div className="revenue_graph">
										<CDBContainer>
											<Bar data={data} options={{ responsive: true }} />
										</CDBContainer>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6 col-sm-12 mt-4">
						<div className="card h-100">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center">
									<h5 className="card-title">Total Repeated Customer</h5>
									<p className="card_number">540k</p>
								</div>
								{/* <h6 className="card-subtitle mb-2 text-muted">
										Last 1 month
									</h6> */}
								<div className="card-text">
									<div className="revenue_graph">
										<CDBContainer>
											<Bar data={data} options={{ responsive: true }} />
										</CDBContainer>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-md-12 col-lg-6 col-sm-10 mb-4 mb-md-4 mb-lg-0">
						<div className="card h-100">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center">
									<h5 className="card-title">Total Visitors</h5>
									<p className="card_number">520.7k</p>
								</div>
								{/* <h6 className="card-subtitle mb-2 text-muted">Last 1 month</h6> */}
								<div className="card-text">
									<div className="revenue_graph">
										<CDBContainer>
											<Line data={data} options={{ responsive: true }} />
										</CDBContainer>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6 col-sm-10">
						<div className="card h-100">
							<div className="card-body">
								<div className="title_top d-flex justify-content-between align-items-center mt-2">
									<h5 className="card-title">Best Partner Seller</h5>
									<HiOutlineDotsVertical style={{ fontWeight: "700" }} />
								</div>
								<div className="card-text mt-4">
									<div className="revenue_graph">
										<PieChart
											colors={palette}
											series={[
												{
													data: [
														{ id: 0, value: 10, label: "Rentomojo" },
														{ id: 1, value: 15, label: "Rentickle" },
														{ id: 2, value: 20, label: "Furlenco" },
													],
												},
											]}
											width={400}
											height={200}
										/>
									</div>
								</div>
								<div className="view-link text-end">
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 mt-4">
					<div className="card h-100">
						<div className="card-body">
							<div className="title_top d-flex justify-content-between align-items-center mt-2">
								<h5 className="card-title">Top Products List</h5>
								<HiOutlineDotsVertical style={{ fontWeight: "700" }} />
							</div>
							<div className="card-text">
								<TableContainer>
									<Table aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell style={{ fontWeight: "550" }}>
													Product Name
												</TableCell>
												<TableCell align="right" style={{ fontWeight: "550" }}>
													Category
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row) => (
												<TableRow
													key={row.name}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<TableCell component="th" scope="row">
														{row.product}
													</TableCell>
													<TableCell align="right">{row.category}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<div className="view-link text-end">
								<a href="#" className="card-link">
									View More
								</a>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="col-7">
							<div className="card">
								<div className="card-body">
									<div className="title_top d-flex justify-content-between align-items-center">
										<h5 className="card-title">Users in last 30 minutes</h5>
										<p className="card_number">16.5k</p>
									</div>
									<div className="card-text">
										<div className="revenue_graph">
											<CDBContainer>
												<Line data={data} options={{ responsive: true }} />
											</CDBContainer>
										</div>
									</div>
									<a href="#" className="card-link">
										View More
									</a>
								</div>
							</div>
						</div> */}
			</div>
		</div>
	);
};

export default Dashboard;
