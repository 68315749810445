import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import rentteLogo from "./../../../assets/images/rentteLogo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LOGIN_ADMIN } from "../../../service/apiAuth";
import { setToken } from "../../../util/validator";
import { CircularProgress } from "@mui/material";
import { set } from "react-hook-form";

const Login = ({ forgotPassword }) => {
	const navigate = useNavigate();
	const [loader, setLoader] = useState(false);

	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {};

	const login = async (e) => {
		e.preventDefault();
		try {
			setLoader(true);
			const { data } = await LOGIN_ADMIN(formData);
			const { code, message, result } = data || {};
			const { token } = result || {};
			if (code === 0) return toast.error(message);
			setToken(token);
			toast.success(message);
			navigate("/dashboard");
		} catch (error) {
			toast.error(error.message);
		} finally {
			setLoader(false);
		}
	};
	return (
		<>
			<form className="login_form" onSubmit={login}>
				<div className="img_container">
					<img src={rentteLogo} />
				</div>
				<div className="form_title">
					<h3>Welcome Admin!</h3>
					<h5>Login to continue</h5>
				</div>
				<div className="form_content">
					<TextField
						sx={{ mb: 2 }}
						id="outlined-size-small"
						label="Email"
						variant="outlined"
						color="success"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<EmailIcon />
								</InputAdornment>
							),
						}}
						size="small"
						fullWidth
						required
						value={formData.email}
						onChange={handleInputChange}
						name="email"
					/>

					<FormControl
						sx={{ mb: 1 }}
						variant="outlined"
						id="outlined-size-small"
						color="success"
						size="small"
						fullWidth
						required
					>
						<InputLabel htmlFor="outlined-adornment-password">
							Password
						</InputLabel>
						<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? "text" : "password"}
							name="password"
							onChange={handleInputChange}
							value={formData.password}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										onMouseDown={handleMouseDownPassword}
										edge="end"
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							label="Password"
						/>
					</FormControl>
					<div className="checkbox_wrap">
						{/* <FormControlLabel
							value="end"
							control={<Checkbox color="success" size="sm" />}
							label="Remember Me"
							labelPlacement="end"
							className="checkbox_text"
						/> */}
						<Link to="" onClick={forgotPassword}>
							Forgot Password?
						</Link>
					</div>
					<div className="btn_container">
						<Button
							variant="contained"
							size="sm"
							sx={{ px: 5 }}
							type="submit"
							className="form_button"
							loading={loader.toString()}
							disabled={loader}
						>
							{loader ? (
								<CircularProgress size={24} color="inherit" />
							) : (
								"Login"
							)}
						</Button>
					</div>
				</div>
			</form>
		</>
	);
};

export default Login;
