import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import { FaUser, FaUserCircle, FaBell } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoLogOut } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LogoutConfirmationModal from "./LogoutConfirmationModal"; // Import the modal component
import "./DashboardHeader.scss";
import { toast } from "react-toastify";
import axios from "axios";

const DashboardHeader = () => {
	const navigate = useNavigate();
	const [showProfileDropdown, setShowProfileDropdown] = useState(false);
	const [showNotificationDropdown, setShowNotificationDropdown] =
		useState(false);
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleProfileToggle = (isOpen) => setShowProfileDropdown(isOpen);
	const handleNotificationToggle = (isOpen) =>
		setShowNotificationDropdown(isOpen);
	const handleLogoutModalShow = () => setShowLogoutModal(true);
	const handleLogoutModalClose = () => setShowLogoutModal(false);

	const handleLogout = () => {
		setShowLogoutModal(false);
		localStorage.clear();
		toast.success("Logged out successfully!");
		navigate("/");
		console.log("Logged out successfully!");
	};

	const handleSearchChange = (event) => {
		setSearchQuery(event.target.value);
	};

	useEffect(() => {
		const fetchSearchResults = async () => {
			if (searchQuery.length > 2) {
				try {
					const response = await axios.get(
						`https://jsonplaceholder.typicode.com/posts?q=${searchQuery}`
					);
					console.log("Search results:", response.data);
					setSearchResults(response.data);
				} catch (error) {
					console.error("Error fetching search results", error);
					toast.error("Failed to fetch search results. Please try again.");
				}
			} else {
				setSearchResults([]);
			}
		};

		const debounceFetch = setTimeout(fetchSearchResults, 300);

		return () => clearTimeout(debounceFetch);
	}, [searchQuery]);

	const profileDropdownMenu = (
		<Dropdown.Menu
			style={{
				position: "absolute",
				zIndex: "1050",
				width: "200px",
				borderRadius: "10px",
				boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
				border: "1px solid #f2f2f2",
				backgroundColor: "#fff",
				paddingTop: "10px",
				paddingBottom: "10px",
			}}
		>
			<Dropdown.Item
				as={"span"}
				style={{ padding: "10px 10px", backgroundColor: "transparent" }}
			>
				<Link
					to="/dashboard/send-otp"
					onClick={() => setShowProfileDropdown(false)}
					style={{
						width: "100%",
						color: "#0c6c6a",
						textDecoration: "none",
						fontWeight: "450",
					}}
				>
					<FaUserCircle style={{ fontSize: "18px", marginRight: "3px" }} />{" "}
					Change Password
				</Link>
			</Dropdown.Item>
			<Dropdown.Item
				className="button_logout"
				as={"span"}
				style={{
					borderTop: "1px solid #f1f1f1",
					paddingBottom: "0",
					paddingTop: "10px",
					marginTop: "5px",
					backgroundColor: "transparent",
				}}
			>
				<span
					onClick={handleLogoutModalShow} // Trigger the logout modal
					style={{ color: "#0c6c6a", fontWeight: "450", cursor: "pointer" }}
				>
					<IoLogOut style={{ fontSize: "18px", marginRight: "3px" }} /> Logout
				</span>
			</Dropdown.Item>
		</Dropdown.Menu>
	);

	const notificationDropdownMenu = (
		<Dropdown.Menu
			style={{
				position: "absolute",
				zIndex: "1050",
				width: "200px",
				borderRadius: "10px",
				boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
				border: "1px solid #f2f2f2",
				backgroundColor: "#fff",
				padding: "10px",
			}}
		>
			<Dropdown.Item
				as={"span"}
				style={{ padding: "10px", color: "#8b8d8d", textAlign: "center" }}
			>
				<span>
					<FaBell />
				</span>
			</Dropdown.Item>
		</Dropdown.Menu>
	);

	return (
		<div className="header_wrapper" id="dashboard-header">
			<div className="dashboard_header">
				<div className="container">
					<div className="header_container">
						<div className="left_container">
							<h3 className="header_title">Dashboard</h3>
						</div>
						<div className="right_container">
							<div className="search_bar">
								<div className="search_container">
									<HiMagnifyingGlass className="search_icon" />
									<input
										type="text"
										placeholder="Search"
										className="search_input"
										value={searchQuery}
										onChange={handleSearchChange}
									/>
									{searchResults.length > 0 && (
										<div className="search_results">
											{searchResults.map((result, index) => (
												<Link
													key={index}
													to={`/item/${result.id}`} // Adjust link as necessary
													onClick={() => setSearchQuery("")}
												>
													{result.title}
												</Link>
											))}
										</div>
									)}
								</div>
							</div>
							<div className="dropdown_notification">
								<Dropdown
									className="dropdown_custom"
									show={showNotificationDropdown}
									onToggle={handleNotificationToggle}
								>
									<Dropdown.Toggle
										id="dropdown-notification"
										style={{
											fontWeight: "400",
											fontSize: "15px",
											position: "relative",
											backgroundColor: "#0c6c6a",
											borderColor: "#0c6c6a",
										}}
									>
										<FaBell style={{ fontSize: "22px", padding: "3px" }} />{" "}
										<span>Notification</span>
									</Dropdown.Toggle>
									{showNotificationDropdown &&
										ReactDOM.createPortal(
											notificationDropdownMenu,
											document.body
										)}
								</Dropdown>
							</div>
							<div className="dropdown_profile">
								<Dropdown
									className="dropdown_custom"
									show={showProfileDropdown}
									onToggle={handleProfileToggle}
								>
									<Dropdown.Toggle
										style={{
											fontWeight: "400",
											fontSize: "15px",
											position: "relative",
											backgroundColor: "#0c6c6a",
											borderColor: "#0c6c6a",
										}}
										id="dropdown-profile"
									>
										<FaUser
											style={{
												fontSize: "22px",
												padding: "3px",
												marginBottom: "3px",
											}}
										/>
										<span>Admin</span>
									</Dropdown.Toggle>
									{showProfileDropdown &&
										ReactDOM.createPortal(profileDropdownMenu, document.body)}
								</Dropdown>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Render the Logout Confirmation Modal */}
			<LogoutConfirmationModal
				show={showLogoutModal}
				handleClose={handleLogoutModalClose}
				handleLogout={handleLogout}
			/>
		</div>
	);
};

export default DashboardHeader;
