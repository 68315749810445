import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	SUBCATEGORY_LIST,
	UPDATE_SUBCATEGORY,
	DELETE_SUBCATEGORY,
} from "../../service/productApi";
import EditSubCategoryModal from "./EditSubCategoryModal";
import DeleteSubCategoryModal from "./DeleteSubCategoryModal";
import ReactPaginate from "react-paginate";
import "./../category/CategoryList.scss";

const SubCategoryList = () => {
	const { REACT_APP_S3_URL } = process.env;
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(true); // Initialize loading as true

	const handleEditClick = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	};

	const handleDeleteClick = (category) => {
		setSelectedCategory(category);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedCategory(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setSelectedCategory(null);
	};

	const handleSaveChanges = async (updatedCategory) => {
		try {
			const formData = new FormData();
			formData.append("id", updatedCategory.id);
			formData.append("subCategoryName", updatedCategory.subCategoryName);
			formData.append("description", updatedCategory.description);
			if (updatedCategory.subcategoryimage) {
				formData.append("subcategoryimage", updatedCategory.subcategoryimage);
			}
			const { data } = await UPDATE_SUBCATEGORY(formData);
			if (data.code === 1) {
				toast.success(data.message);
				getSubCategoryList(currentPage);
				setShowEditModal(false);
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const handleDeleteCategory = async () => {
		try {
			const { data } = await DELETE_SUBCATEGORY({ id: selectedCategory.id });
			if (data.code === 1) {
				toast.success(data.message);
				getSubCategoryList(currentPage);
				handleDeleteModalClose();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const getSubCategoryList = async (page) => {
		setLoading(true); // Start loading
		try {
			const { data } = await SUBCATEGORY_LIST({ page });
			setSubCategoryList(data.result.subcategories);
			setTotalPages(data.result.pagination.totalPages);
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false); // Stop loading
		}
	};

	useEffect(() => {
		getSubCategoryList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Category</th>
						<th scope="col">Subcategory</th>
						<th scope="col">Image</th>
						<th scope="col">Description</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="6" className="text-center">
								Loading...
							</td>
						</tr>
					) : subCategoryList.length > 0 ? (
						subCategoryList.map((category, index) => (
							<tr key={category.id}>
								<td>
									{(currentPage - 1) * subCategoryList.length + (index + 1)}
								</td>
								<td>{category?.category?.categoryName}</td>
								<td>{category.subCategoryName}</td>
								<td>
									<img
										src={REACT_APP_S3_URL + category.subcategoryimage}
										alt={category.subCategoryImage}
										style={{
											width: "40px",
											height: "30px",
											objectFit: "cover",
										}}
									/>
								</td>
								<td>{category.description}</td>
								<td>
									<button
										className="btn btn-primary btn-sm btnPrimary"
										onClick={() => handleEditClick(category)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(category)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="6" className="text-center">
								No sub categories found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(e) => handlePageChange(e.selected + 1)}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{/* Render the edit modal */}
			{selectedCategory && (
				<EditSubCategoryModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					subCategoryData={selectedCategory}
					handleSave={handleSaveChanges}
				/>
			)}

			{/* Render the delete modal */}
			{selectedCategory && (
				<DeleteSubCategoryModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					handleDelete={handleDeleteCategory}
				/>
			)}
		</div>
	);
};

export default SubCategoryList;
