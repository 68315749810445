import React, { useState, useEffect } from "react";
import "./../category/AddCategory.scss";
import {
	CREATE_CATEGORY,
	CATEGORY_LIST,
	CREATE_SUBCATEGORY,
} from "../../service/productApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ImageUploader from "react-images-upload";
import { IoMdCloseCircle } from "react-icons/io";

const AddSubCategory = () => {
	const navigate = useNavigate();
	const [subcategoryimage, setSubCategoryImage] = useState([]);

	const onDrop = (pictureFiles, pictureDataURLs) => {
		setSubCategoryImage(pictureFiles);
	};

	const [formData, setFormData] = useState({
		categoryId: "",
		description: "",
		subCategoryName: "",
	});

	const [isLoading, setIsLoading] = useState(false);
	const [productTypeOptions, setProductTypeOptions] = useState([]);
	const [error, setError] = useState("");

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (name === "subCategoryName" && /^[a-z]/.test(value)) {
			setError(
				"Sub category name is case sensitive and should start with an uppercase letter."
			);
		} else {
			setError("");
		}

		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);

		try {
			const formDataPayload = new FormData();
			formDataPayload.append("subCategoryName", formData.subCategoryName);
			formDataPayload.append("categoryId", formData.categoryId);
			formDataPayload.append("description", formData.description);
			subcategoryimage.forEach((image, index) => {
				formDataPayload.append("subcategoryimage", image);
			});
			const { data } = await CREATE_SUBCATEGORY(formDataPayload);
			const { code, message } = data;
			if (code === 0) {
				toast.error(message);
				setIsLoading(false);
				return;
			}
			toast.success(message);
			navigate("/dashboard/product-list");
		} catch (error) {
			if (error.response) {
				const { status, data } = error.response;
				if (status === 409) {
					toast.error(data.message || "A conflict occurred. Please try again.");
				} else {
					toast.error(data.message || "An error occurred. Please try again.");
				}
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
			setIsLoading(false);
		}
	};

	const fetchCategories = async () => {
		try {
			const { data } = await CATEGORY_LIST({ page: 1,isSubCategoryRequest: true });
			const options = data.result.categories.map((category) => ({
				label: category.categoryName,
				value: category.id,
			}));
			setProductTypeOptions(options);
		} catch (error) {
			toast.error(
				error.message || "An error occurred while fetching categories."
			);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="form_container mt-4 mb-4">
						<div className="card">
							<div className="card-body">
								<h3 className="card-title text-center">Add Sub Category</h3>
								<form onSubmit={handleSubmit}>
									<div className="form-group row">
										<label
											htmlFor="product-type"
											className="col-sm-4 col-form-label"
										>
											Category Type:
										</label>
										<div className="col-sm-8">
											<select
												id="product-type"
												name="categoryId"
												className="form-control form-select"
												value={formData.categoryId}
												onChange={handleChange}
												required
												disabled={isLoading}
											>
												<option value="" disabled selected>
													Select category type
												</option>
												{productTypeOptions.map((option) => (
													<option key={option.value} value={option.value}>
														{option.label}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="category-name"
											className="col-sm-4 col-form-label"
										>
											Sub Category Name:
										</label>
										<div className="col-sm-8">
											<input
												type="text"
												className="form-control"
												id="category-name"
												name="subCategoryName"
												placeholder="Enter sub category name"
												value={formData.subCategoryName}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && <small className="text-danger">{error}</small>}
										</div>
									</div>

									<div className="form-group row">
										<label htmlFor="image" className="col-sm-4 col-form-label">
											Upload Image:
										</label>
										<div className="col-sm-8">
											<ImageUploader
												withIcon={true}
												buttonText="Choose Images"
												onChange={onDrop}
												imgExtension={[".jpg", ".jpeg"]}
												maxFileSize={5242880}
												withPreview={true}
											/>
										</div>
									</div>
									<div className="form-group row">
										<label
											htmlFor="description"
											className="col-sm-4 col-form-label"
										>
											Description:
										</label>
										<div className="col-sm-8">
											<textarea
												type="text"
												id="description"
												name="description"
												className="form-control"
												placeholder="Describe your sub category"
												rows="6"
												value={formData.description}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
										</div>
									</div>
									<div className="form-group row mt-4">
										<div className="col-sm-4"></div>
										<div className="col-sm-8 button-group">
											<input
												type="submit"
												value={isLoading ? "Submitting..." : "Submit"}
												className="btn btn-success buttonSuccess"
												style={{ width: "150px", marginRight: "10px" }}
												disabled={isLoading}
											/>
											<input
												type="button"
												value="Cancel"
												className="btn btn-secondary"
												style={{ width: "150px" }}
												onClick={() =>
													setFormData({
														categoryName: "",
														description: "",
														productType: "",
													})
												}
												disabled={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSubCategory;
