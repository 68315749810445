import React from "react";
import { IoSettings } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";
import "./dashboard/dashboard.scss";
import { Link } from "react-router-dom";

const Settings = () => {
	return (
		<div className="page_section">
			<div className="center-container">
				<IoSettings />
				Settings Page
				<Link to="/dashboard">
					Go to dashboard <GoArrowRight />
				</Link>
			</div>
		</div>
	);
};

export default Settings;
