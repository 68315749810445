import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import EditProductModal from "./EditProductModal";
import DeleteProductModal from "./DeleteProductModal";
import "../category/CategoryList.scss";
import {
	DELETE_PRODUCT,
	GET_PRODUCT_LIST,
	UPDATE_PRODUCT,
} from "../../service/productApi";
import ReactPaginate from "react-paginate";

const ProductList = () => {
	const { REACT_APP_S3_URL } = process.env;
	const [productList, setProductList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleEditClick = (product) => {
		setSelectedProduct(product);
		setShowEditModal(true);
	};
	console.log(showEditModal);

	const handleDeleteClick = (product) => {
		setSelectedProduct(product);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedProduct(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setSelectedProduct(null);
	};

	const getProductList = async () => {
		try {
			setLoading(true);
			const payload = {
				page: currentPage,
				limit: 10,
			};
			const { data } = await GET_PRODUCT_LIST(payload);
			setProductList(data.result.products);
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			toast.error("Failed to fetch products");
		} finally {
			setLoading(false);
		}
	};

	const handleDeleteProduct = async () => {
		try {
			setLoading(true);
			await DELETE_PRODUCT(selectedProduct.id);
			setShowDeleteModal(false);
			toast.success("product delete successfully");
			getProductList();
		} catch (error) {
			toast.error("Failed to delete products");
		} finally {
			setLoading(false);
		}
	};

	const handleSaveChanges = async (updatedProduct) => {
		try {
			const formData = new FormData();
			formData.append("productname", updatedProduct.productname);
			formData.append("description", updatedProduct.description);
			formData.append("categoryId", updatedProduct.category.id); // Assuming you want to send the category ID
			formData.append("subCategoryId", updatedProduct.subcategory.id); // Assuming you want to send the subcategory ID
			formData.append("unitprice", updatedProduct.unitprice);
			formData.append("quantity", updatedProduct.quantity);
			formData.append("rentalType", updatedProduct.rentalType);
			formData.append("rentalperiod", updatedProduct.rentalperiod);
			// Append product images
			updatedProduct.productImages &&
				updatedProduct.productImages.forEach((image, index) => {
					formData.append(`productImages`, image);
				});

			// Append product ID if needed

			// Call your API to update the product
			const { data } = await UPDATE_PRODUCT(formData, updatedProduct.id);

			toast.success("Product updated successfully");
			getProductList(); // Refresh the product list after update
			handleEditModalClose(); // Close the modal after saving changes
		} catch (error) {
			toast.error("Failed to update product");
		}
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		getProductList();
	}, [currentPage]);

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th>
						<th scope="col">Brand Name</th>
						<th scope="col">Product Name</th>
						<th scope="col">Category</th>
						<th scope="col">Sub Category</th>
						<th scope="col">Image</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="7" className="text-center">
								Loading...
							</td>
						</tr>
					) : productList.length > 0 ? (
						productList.map((product, index) => (
							<tr key={product.id}>
								<td>{(currentPage - 1) * 10 + (index + 1)}</td>
								<td>{product.brandname}</td>
								<td>{product.productname}</td>
								<td>{product.category.name}</td>
								<td>{product.subcategory.name}</td>
								<td>
									<img
										src={`${REACT_APP_S3_URL}${product.productImages[0]}`}
										alt={product.productImages[0]}
										style={{
											width: "40px",
											height: "30px",
											objectFit: "cover",
										}}
									/>
								</td>
								<td>
									<button
										className="btn btn-primary btn-sm btnPrimary"
										onClick={() => handleEditClick(product)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(product)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="7" className="text-center">
								No products found.
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{totalPages > 1 && (
				<ReactPaginate
					previousLabel={"Previous"}
					nextLabel={"Next"}
					breakLabel={"..."}
					breakClassName={"break-me"}
					pageCount={totalPages}
					marginPagesDisplayed={2}
					pageRangeDisplayed={5}
					onPageChange={(e) => handlePageChange(e.selected + 1)}
					containerClassName={"pagination justify-content-center"}
					activeClassName={"active"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakLinkClassName={"page-link"}
				/>
			)}

			{showEditModal && (
				<EditProductModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					productData={selectedProduct}
					handleSave={handleSaveChanges}
				/>
			)}

			{/* Delete Modal */}
			{selectedProduct && (
				<DeleteProductModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					handleDelete={handleDeleteProduct} // Implement this function to delete a product
					loading={loading}
				/>
			)}
		</div>
	);
};

export default ProductList;
