export const CONSTANT_URL = {
    LOGIN: "/admin/auth/login",
    FORGET_PASSWORD: "/admin/auth/forget-password",
    RESET_PASSWORD: "/admin/auth/reset-password",
    BLOCK_UNBLOCK_USER: "/admin/user/block-and-unblock-user",
    CREATE_CATEGORY: "/admin/product/create-category",
    CATEGORY_LIST: "/admin/product/category-list",
    DELETE_CATEGORY: "/admin/product/delete-category",
    UPDATE_CATEGORY: "/admin/product/update-category",
    CREATE_SUBCATEGORY: "/admin/product/create-subcategory",
    DELETE_SUBCATEGORY: "/admin/product/delete-subcategory",
    UPDATE_SUBCATEGORY: "/admin/product/update-subcategory",
    SUBCATEGORY_LIST: "/admin/product/subcategory-list",
    USER_LIST: "/user/auth/user-list",
    PARTNER_LIST: "/user/partner/partner-list",
    SEND_OTP: "/admin/auth/send-otp",
    VERIFY_OTP: "/admin/auth/verify-otp",
    CHANGE_PASSWORD: "admin/auth/change-password",
    CREATE_PRODUCT: "/partner/product/create-product",
    GET_SUBCATEGORY_BY_CATEGORY: "/user/product/subcategory-list",
    GET_PRODUCT_LIST: "/admin/product/get-all-product",
    UPDATE_PRODUCT: "/partner/product/edit-product",
    DELETE_PRODUCT: "/partner/product/delete-product",
    BULK_EMAIL: "/notification/email/bulk-email",
    SEND_BULK_EMAIL: "/notification/email/send-email-notification"
}