import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./adminprofile.scss";
import { SEND_OTP } from "../../service/apiAuth";

const OTP = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const [loading, setLoading] = useState(false);
	// const [resendLoading, setResendLoading] = useState(false);
	const navigate = useNavigate();

	const sendOtpHandler = async (data) => {
		setLoading(true);
		// setResendLoading(false);
		await handleOtpSending(data);
		setLoading(false);
	};

	// const resendOtpHandler = async (data) => {
	// 	setResendLoading(true);
	// 	setLoading(false);
	// 	await handleOtpSending(data);
	// 	setResendLoading(false);
	// };

	const handleOtpSending = async (data) => {
		try {
			const response = await SEND_OTP({
				mobile: data.phoneNumber,
				countryCode: "+91",
			});
			if (response.status === 200) {
				reset();
				toast.success("OTP sent successfully!");
				navigate("/dashboard/verify-otp", {
					state: { mobile: data.phoneNumber },
				});
			} else {
				toast.error("Failed to send OTP. Please try again.");
			}
		} catch (error) {
			toast.error("Failed to send OTP. Please try again.");
		}
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-sm-12 col-md-12 col-lg-10">
					<div className="profile_content mt-5 mb-3">
						<div className="title">
							<h4 className="text-center">Send OTP</h4>
						</div>
						<div className="content_inner">
							<form onSubmit={handleSubmit(sendOtpHandler)}>
								<div className="form_field">
									<label htmlFor="phoneNumber" className="label">
										Phone number
									</label>
									<div className="password">
										<input
											type="text"
											id="phoneNumber"
											placeholder="Enter your phone number"
											className="form-control input"
											{...register("phoneNumber", {
												required: "Phone number is required",
												pattern: {
													value: /^\d{10}$/,
													message: "Phone number must be 10 digits",
												},
											})}
										/>
										{errors.phoneNumber && (
											<small className="text-danger">
												{errors.phoneNumber.message}
											</small>
										)}
									</div>
									<div className="button_wrap text-center mt-4">
										<Button
											type="submit"
											className="btn btn-success buttonSuccess"
											style={{ width: "150px" }}
											disabled={loading}
										>
											{loading ? "Sending..." : "Send OTP"}
										</Button>
										{/* <Button
											type="button"
											className="btn btn-success buttonSuccess"
											style={{ width: "150px" }}
											disabled={loading || resendLoading}
											onClick={handleSubmit(resendOtpHandler)}
										>
											{resendLoading ? "Sending..." : "Resend"}
										</Button> */}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OTP;
