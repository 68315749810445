import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./../../pages/category/CategoryList.scss";

const LogoutConfirmationModal = ({ show, handleClose, handleLogout }) => {
	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title>Confirm Logout</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>Are you sure you want to log out?</p>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleLogout} className="btnPrimary">
					Logout
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default LogoutConfirmationModal;
