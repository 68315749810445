import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";

const faqData = [
	{
		question: "How does Rentte work?",
		answer:
			"Rentte is an online platform where you can get furniture on rent, electronics on rent, cars for rent, house on rent, sports equipment on rent, celebrities for rent, travel kit for rent, and medical kits for rent.",
	},
	{
		question: "How can I list my product on Rentte?",
		answer: `
			<b>Step 1:</b> Register/Signup on Rentte. <br><b>Step 2:</b> Click pictures of the product(s) that you want to list for renting. Select the appropriate category your product falls into.<br>
			<b>Step 3:</b> Calculate the rent for your product, create your listing and wait for approval within 24 hours.<br> <b>Step 4:</b> When the product is rented by a user, you will be notified for pickup of your product.<br>
			<b>Step 5:</b> Get paid by our secure payments system within 2-3 working days from the date of pickup.<br> <b>Step 6:</b> Get your rented product back after the termination of the renting period.
		`,
	},
	{
		question: "How do I rent a product on Rentte?",
		answer: `
			<b>Step 1:</b> Register/Signup on Rentte.<br> <b>Step 2:</b> Search among the list of products you’re searching to rent.<br>
			<b>Step 3:</b> Select the period/dates on which you need to rent the product and add to cart.<br> <b>Step 4:</b> Choose the self-pickup option or the delivered to your address option.<br>
			<b>Step 5:</b> Make the payment and complete the KYC process. <br> <b>Step 6:</b> Return the item to the owner after the renting period and leave a review.
		`,
	},
	{
		question: "Why should I rent from Rentte?",
		answer: `
			We consider ourselves different from other renting platforms for several reasons:<br>
			1. Wide range of products and services to choose from.<br>
			2. Door-to-door pickup and delivery.<br>
			3. Zero paperwork.<br>
			4. No maximum renting period.<br>
			5. Rent your own products and earn.<br>
		`,
	},
	{
		question: "What is the minimum period for rent of any item?",
		answer:
			"All items have a minimum period of rent. The period varies depending on the nature of the item. The minimum period is defined for each item at its check-out page so as to cover various administrative and incidental costs attached with renting of such items.",
	},
];

const Faqs = () => {
	return (
		<section className="faq_section mt-5">
			<div className="container">
				<div className="row">
					<div className="text-center mb-3">
						<h3>
							<b>Frequently Asked Questions</b>
						</h3>
					</div>
				</div>
				<div className="row p-5">
					<Accordion>
						{faqData.map((faq, index) => (
							<Accordion.Item eventKey={index.toString()} key={index}>
								<Accordion.Header>
									{`${index + 1}. ${faq.question}`}
								</Accordion.Header>
								<Accordion.Body
									dangerouslySetInnerHTML={{ __html: faq.answer }}
								/>
							</Accordion.Item>
						))}
					</Accordion>
				</div>
			</div>
		</section>
	);
};

export default Faqs;
