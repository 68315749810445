import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "./adminprofile.scss";
import { VERIFY_OTP } from "../../service/apiAuth";
import { SEND_OTP } from "../../service/apiAuth";

const VerifyOTP = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [loading, setLoading] = useState(false);
	const [resendLoading, setResendLoading] = useState(false);
	const [resendTimer, setResendTimer] = useState(30); // Timer set for 30 seconds
	const [error, setError] = useState("");
	const location = useLocation();
	const navigate = useNavigate();
	const { state } = location || {};
	const { mobile } = state || {};
	console.log("verify otp", mobile);

	// Countdown timer for the resend button
	useEffect(() => {
		let timer;
		if (resendTimer > 0) {
			timer = setTimeout(() => {
				setResendTimer((prev) => prev - 1);
			}, 1000);
		}

		return () => clearTimeout(timer);
	}, [resendTimer]);

	const resendOtpHandler = async () => {
		// Resend OTP without triggering form validation
		setResendLoading(true);
		setLoading(false);
		try {
			const response = await SEND_OTP({
				mobile: mobile,
				countryCode: "+91",
			});
			if (response.status === 200) {
				reset();
				toast.success("OTP resent successfully!");
				setResendTimer(30); // Reset the timer to 30 seconds
			} else {
				toast.error("Failed to resend OTP. Please try again.");
			}
		} catch (error) {
			toast.error("Failed to resend OTP. Please try again.");
		}
		setResendLoading(false);
	};

	const onSubmit = async (data) => {
		setLoading(true);
		setError("");
		try {
			// API endpoint
			const response = await VERIFY_OTP({
				mobile: mobile,
				countryCode: "+91",
				otp: data.otp,
			});
			const { result } = response.data || {};
			const { otpDetail } = result || {};
			if (response.status === 200) {
				reset();
				toast.success("OTP verification successful!");
				navigate("/dashboard/change-password", {
					state: {
						otpId: otpDetail.id,
						mobile: mobile,
					},
				});
				console.log("OTP Verified Successfully");
			} else {
				toast.error("Failed to verify OTP. Please try again.");
			}
		} catch (error) {
			toast.error("Failed to verify OTP. Please try again.");
		}
		setLoading(false);
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="profile_content mt-5 mb-3">
						<div className="title">
							<h4 className="text-center">Verify OTP</h4>
							<div className="content_inner">
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="form_field">
										<label htmlFor="otp" className="label">
											Enter OTP
										</label>
										<div className="password">
											<input
												type="text"
												id="otp"
												placeholder="Enter otp here"
												className="form-control input"
												{...register("otp", {
													required: "OTP is required",
													pattern: {
														value: /^[0-9]{6}$/,
														message: "OTP must be 6 digits",
													},
												})}
											/>
											{errors.otp && (
												<small className="text-danger">
													{errors.otp.message}
												</small>
											)}
										</div>
										<div className="button_wrap text-center mt-4">
											<Button
												type="submit"
												className="btn btn-success buttonSuccess"
												style={{ width: "150px" }}
												disabled={loading}
											>
												{loading ? "Verifying..." : "Verify OTP"}
											</Button>
											<Button
												type="button"
												className="btn btn-success buttonSuccess"
												style={{ width: "150px" }}
												disabled={loading || resendLoading || resendTimer > 0}
												onClick={resendOtpHandler} // Separate handler for resend button
											>
												{resendLoading
													? "Sending..."
													: resendTimer > 0
													? `Resend in ${resendTimer}s`
													: "Resend"}
											</Button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerifyOTP;
