import React, { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import rentteLogo from "./../../../assets/images/rentteLogo.png";
import { toast } from "react-toastify";
import Stack from '@mui/material/Stack';
import { FORGET_PASSWORD } from '../../../service/apiAuth';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = ({ backToLogin }) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [emailStatus, setEmailStatus] = useState(false);

  const forgetPassword = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);
      const { data } = await FORGET_PASSWORD({ email });
      const { code, message } = data || {};
      if (code === 1) {
        return toast.success(message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <form className="forgot_password">
        <div className="img_container">
          <img src={rentteLogo} alt="Logo" />
        </div>
        <div className="form_title">
          <h3>Forgot Password?</h3>
          <p>Enter your email to set a new password!</p>
        </div>
        <div className="form_field">
          <TextField
            id="outlined-size-small"
            label="Email"
            variant="outlined"
            color="success"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            size="small"
            fullWidth
            required
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            disabled={emailStatus}
          />
        </div>
        <div className="btn_container">
          <Stack spacing={1}>
            <Button
              type="submit"
              variant="contained"
              size="sm"
              className="form_button"
              onClick={forgetPassword}
              disabled={loader}
            >
              {loader ? <CircularProgress size={24} color="inherit" /> : 'Send Link'}
            </Button>
            <Button
              variant="contained"
              type="submit"
              size="sm"
              className="form_button"
              onClick={backToLogin}
              disabled={loader}
            >
              Back to Login
            </Button>
          </Stack>
        </div>
      </form>
    </>
  );
};

export default ForgotPassword;
