import React, { useState, useEffect } from 'react';
import './../category/AddCategory.scss';
import ImageUploader from 'react-images-upload';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
	CATEGORY_LIST,
	CREATE_PRODUCT,
	GET_SUBCATEGORY_BY_CATEGORYID,
	SUBCATEGORY_LIST,
} from '../../service/productApi';

const AddProduct = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [query, setQuery] = useState('');
	const [results, setResults] = useState([]);
	const [selected, setSelected] = useState(null);
	const [debounceTimer, setDebounceTimer] = useState(null);
	const [categoryList, setCategoryList] = useState([]);
	const [subcategoryList, setSubCategoryList] = useState([]);
	const [formData, setFormData] = useState({
		brandName: '',
		productName: '',
		categoryId: '',
		subCategoryId: '',
		description: '',
		price: 0,
		rentalType: '',
		totalPrice: 1,
		rentalQuantity: 1,
		rentalPeriod: 1

	});
	const [images, setImages] = useState([]);
	const searchLatLong = async (query) => {
		const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
			query
		)}&format=json&addressdetails=1`;

		try {
			const response = await axios.get(url, {
				headers: {
					'User-Agent': 'YourAppName/1.0 (your.email@example.com)', // Replace with your app name and contact info
				},
				timeout: 8000, // Set timeout to 8 seconds
			});

			setResults(response.data);
		} catch (error) {
			if (error.code === 'ECONNABORTED') {
				console.error('Error: Request timed out');
			} else {
				console.error('Error:', error);
			}
		}
	};

	const getCategoryList = async () => {
		try {
			const { data } = await CATEGORY_LIST({
				page: 1,
				isSubCategoryRequest: true,
			});
			const { result } = data || {};
			const { categories } = result || {};
			setCategoryList(categories);
			console.log('categories', categories);
		} catch (error) {
			console.log('exception msg', error);
		}
	};

	const getSubCategoryList = async (categoryId) => {
		try {
			const { data } = await GET_SUBCATEGORY_BY_CATEGORYID(categoryId);
			const { result } = data || {};
			const { subcategories } = result || {};
			setSubCategoryList(subcategories);
		} catch (error) {
			console.log('exception msg', error);
		}
	};
	useEffect(() => {
		getCategoryList();
		if (query) {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
			const newDebounceTimer = setTimeout(() => {
				searchLatLong(query);
			}, 500); // Delay of 500ms

			setDebounceTimer(newDebounceTimer);
		} else {
			setResults([]);
		}

		return () => {
			if (debounceTimer) {
				clearTimeout(debounceTimer);
			}
		};
	}, [query]);

	const handleSelect = (result) => {
		const placeName = result.display_name;
		setSelected({ lat: result.lat, lon: result.lon });
		setQuery(placeName);
		setResults([]); // Clear the suggestions after selection
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
	
		// Update form data
		let updatedFormData = {
			...formData,
			[name]: value,
		};
	
		// If rentalQuantity, rentalPeriod, or price changes, recalculate totalPrice
		if (name === 'rentalQuantity' || name === 'rentalPeriod' || name === 'price') {
			const rentalQuantity = name === 'rentalQuantity' ? value : formData.rentalQuantity;
			const rentalPeriod = name === 'rentalPeriod' ? value : formData.rentalPeriod;
			const price = name === 'price' ? value : formData.price;
	
			// Calculate total price as rentalQuantity * rentalPeriod * price
			const totalPrice = rentalQuantity * rentalPeriod * price;
	
			// Update the totalPrice in the formData
			updatedFormData = {
				...updatedFormData,
				totalPrice: totalPrice || 1, // Ensure totalPrice defaults to 0 if any value is missing
			};
		}
		setFormData(updatedFormData);
		if (name === "categoryId") {
			getSubCategoryList(value);
		}
	};
	

	const handleImageUpload = (files) => {
		setImages(files);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const formDataToSend = new FormData();
		formDataToSend.append('brandname', formData.brandName);
		formDataToSend.append('productname', formData.productName);
		formDataToSend.append('categoryId', formData.categoryId);
		formDataToSend.append('subCategoryId', formData.subCategoryId);
		formDataToSend.append('description', formData.description);
		formDataToSend.append('unitprice', formData.price);
		formDataToSend.append("rentalType", formData.rentalType);
		formDataToSend.append("rentalperiod", formData.rentalPeriod);
		formDataToSend.append("quantity", formData.rentalQuantity);
		formDataToSend.append("amount", formData.totalPrice);
		formDataToSend.append('latitude', selected?.lat || '');
		formDataToSend.append('longitude', selected?.lon || '');
		images.forEach((file) => {
			formDataToSend.append('productImages', file);
		});

		try {
			const { data } = await CREATE_PRODUCT(formDataToSend);
			const { code, message } = data;
			if (code === 0) {
				toast.error(message);
				return;
			}
			navigate('/dashboard/product-list');
			toast.success(message);
		} catch (error) {
			setError('Failed to add product.');
			toast.error('Failed to add product.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='container'>
			<div className='row justify-content-center'>
				<div className='col-10'>
					<div className='form_container mt-4 mb-4'>
						<div className='card'>
							<div className='card-body'>
								<h3 className='card-title text-center'>Add Product</h3>
								<form onSubmit={handleSubmit}>
									<div className='form-group row'>
										<label
											htmlFor='brand-name'
											className='col-sm-4 col-form-label'
										>
											Brand Name:
										</label>
										<div className='col-sm-8'>
											<input
												type='text'
												className='form-control'
												id='brand-name'
												name='brandName'
												placeholder='Enter brand name'
												value={formData.brandName}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && (
												<small className='text-danger'>
													{error}
												</small>
											)}
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='product-name'
											className='col-sm-4 col-form-label'
										>
											Product Name:
										</label>
										<div className='col-sm-8'>
											<input
												type='text'
												className='form-control'
												id='product-name'
												name='productName'
												placeholder='Enter product name'
												value={formData.productName}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && (
												<small className='text-danger'>
													{error}
												</small>
											)}
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='product-name'
											className='col-sm-4 col-form-label'
										>
											Product Price:
										</label>
										<div className='col-sm-8'>
											<input
												type='text'
												className='form-control'
												id='product-price'
												name='price'
												placeholder='Enter product price'
												value={formData.price}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && (
												<small className='text-danger'>
													{error}
												</small>
											)}
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='rental-type'
											className='col-sm-4 col-form-label'
										>
											Rental Type:
										</label>
										<div className='col-sm-8'>
											<select
												id='rental-type'
												name='rentalType'
												className='form-control form-select'
												value={formData.rentalType}
												onChange={handleChange}
												required
											>
												<option value='' disabled>
													Select rental type
												</option>
												<option value="hourly">Hourly</option>
												<option value="day">Day</option>
												<option value="month">Month</option>
											</select>
										</div>
									</div>

									<div className='form-group row'>
										<label
											htmlFor='rental-period'
											className='col-sm-4 col-form-label'
										>
											Rental Period :
										</label>
										<div className='col-sm-8'>
											<input
												type='number'
												className='form-control'
												id='rental-period'
												name='rentalPeriod'
												placeholder='Enter rental period'
												value={formData.rentalPeriod}
												onChange={handleChange}
												required
											/>
										</div>
									</div>

									<div className='form-group row'>
										<label
											htmlFor='rental-quantity'
											className='col-sm-4 col-form-label'
										>
											Rental Quantity:
										</label>
										<div className='col-sm-8'>
											<input
												type='number'
												className='form-control'
												id='rental-quantity'
												name='rentalQuantity'
												placeholder='Enter quantity'
												value={formData.rentalQuantity}
												onChange={handleChange}
												min='1'
												required
											/>
										</div>
									</div>

									<div className='form-group row'>
										<label
											htmlFor='total-price'
											className='col-sm-4 col-form-label'
										>
											Total Price:
										</label>
										<div className='col-sm-8'>
											<input
												type='text'
												className='form-control'
												id='total-price'
												name='totalPrice'
												placeholder='Enter total price'
												value={formData.totalPrice}
												disabled={true}
											/>
										</div>
									</div>

									<div className='form-group row'>
										<label
											htmlFor='category-name'
											className='col-sm-4 col-form-label'
										>
											Category Name:
										</label>
										<div className='col-sm-8'>
											<select
												id='category-name'
												name='categoryId'
												className='form-control form-select'
												value={formData.categoryId}
												onChange={handleChange}
												required
												disabled={isLoading}
											>
												<option value='' disabled>
													Select category name
												</option>
												{categoryList.map((option) => (
													<option
														key={option.id}
														value={option.id}
													>
														{option.categoryName}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='subcategory-name'
											className='col-sm-4 col-form-label'
										>
											Sub Category Name:
										</label>
										<div className='col-sm-8'>
											<select
												id='subcategory-name'
												name='subCategoryId'
												className='form-control form-select'
												value={formData.subCategoryId}
												onChange={handleChange}
												required
												disabled={isLoading}
											>
												<option value='' disabled>
													Select sub category name
												</option>
												{subcategoryList?.map((option) => (
													<option
														key={option.id}
														value={option.id}
													>
														{option.subCategoryName}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='location-search'
											className='col-sm-4 col-form-label'
										>
											Search location:
										</label>
										<div className='col-sm-8'>
											<input
												type='text'
												className='form-control'
												value={query}
												onChange={(e) => setQuery(e.target.value)}
												placeholder='Enter a location'
											/>
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='list-search'
											className='col-sm-4 col-form-label'
										>
											List Search:
										</label>
										<div className='col-sm-8'>
											<select
												id='list-search'
												name='listSearch'
												className='form-control form-select'
												required
												onChange={(e) =>
													handleSelect(JSON.parse(e.target.value))
												}
												disabled={isLoading}
											>
												<option value='' disabled>
													Select location
												</option>
												{results.map((option) => (
													<option
														key={option.display_name}
														value={JSON.stringify(option)}
													>
														{option.display_name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='image'
											className='col-sm-4 col-form-label'
										>
											Upload Image:
										</label>
										<div className='col-sm-8'>
											<ImageUploader
												withIcon={true}
												buttonText='Choose Images'
												onChange={handleImageUpload}
												imgExtension={['.jpg', '.jpeg']}
												maxFileSize={5242880}
												withPreview={true}
											/>
											{error && (
												<small className='text-danger'>
													{error}
												</small>
											)}
										</div>
									</div>
									<div className='form-group row'>
										<label
											htmlFor='description'
											className='col-sm-4 col-form-label'
										>
											Description:
										</label>
										<div className='col-sm-8'>
											<textarea
												className='form-control'
												id='description'
												name='description'
												rows='3'
												placeholder='Enter product description'
												value={formData.description}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
										</div>
									</div>
									<div className='form-group row mt-4'>
										<div className='col-sm-4'></div>
										<div className='col-sm-8 button-group'>
											<input
												type='submit'
												value={
													isLoading ? 'Submitting...' : 'Submit'
												}
												className='btn btn-success buttonSuccess'
												style={{
													width: '150px',
													marginRight: '10px',
												}}
												disabled={isLoading}
											/>
											<input
												type='button'
												value='Cancel'
												className='btn btn-secondary'
												style={{ width: '150px' }}
												// onClick={() =>
												// 	setFormData({
												// 		categoryId: "",
												// 		description: "",
												// 		productType: "",
												// 	})
												// }
												disabled={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddProduct;
