import React, { useState, useEffect } from "react";
import rentteLogo from "./../../assets/images/rentteLogo.png";
import { NavLink } from "react-router-dom";
import { MdSpaceDashboard, MdMenu } from "react-icons/md";
import { PiStackPlusFill } from "react-icons/pi";
import { IoCartSharp, IoSettings } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { MdPolicy, MdHelpCenter } from "react-icons/md";
import { IoMdListBox } from "react-icons/io";
import { RxDotFilled } from "react-icons/rx";
import { Dropdown, Button } from "react-bootstrap";
import "./SideBar.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const SideBar = () => {
	const [isExpanded, setIsExpanded] = useState(true);
	const [activeDropdown, setActiveDropdown] = useState(null);
	const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);

	const toggleSidebar = () => {
		setIsExpanded(!isExpanded);
	};

	const toggleOffCanvas = () => {
		setIsOffCanvasOpen(!isOffCanvasOpen);
	};

	const handleDropdownToggle = (dropdown) => {
		setActiveDropdown(activeDropdown === dropdown ? null : dropdown);
	};

	useEffect(() => {
		const path = window.location.pathname;
		if (path.includes("/add-category") || path.includes("/category-list")) {
			setActiveDropdown("category");
		} else if (
			path.includes("/add-subcategory") ||
			path.includes("/subcategory-list")
		) {
			setActiveDropdown("subcategory");
		} else if (
			path.includes("/add-product") ||
			path.includes("/product-list")
		) {
			setActiveDropdown("product");
		} else if (path.includes("/partner") || path.includes("/customer")) {
			setActiveDropdown("user");
		}
	}, []);

	return (
		<>
			<div
				className={`hamburger-menu ${isOffCanvasOpen ? "active" : ""}`}
				onClick={toggleSidebar}
			>
				<MdMenu aria-label="Toggle sidebar" />
			</div>
			<div
				className={`${
					isExpanded ? "expanded" : "collapsed"
				} sidebar_section off-canvas-sidebar ${isOffCanvasOpen ? "" : ""}`}
				id="sidebar"
			>
				<aside className="aside">
					<div className="logo_container w-100 py-3">
						<div
							className="d-flex justify-content-center align-item-center"
							height="80"
						>
							<img
								src={rentteLogo}
								alt="Brand Logo"
								className="img-fluid ms-2 brandLogo cursor"
								width="80"
							/>
							<div
								className="w-fit ms-auto sidebar_toggler cursor"
								onClick={toggleSidebar}
							>
								<MdMenu aria-label="Toggle sidebar" />
							</div>
						</div>
					</div>
					<div className="main_menu">
						<ul className="sidebar-links">
							<li>
								<NavLink
									to="/dashboard"
									className={({ isActive }) =>
										isActive && window.location.pathname === "/dashboard"
											? "active"
											: undefined
									}
								>
									<MdSpaceDashboard />
									<span>Dashboard</span>
								</NavLink>
							</li>
							<li>
								<Dropdown
									show={activeDropdown === "category"}
									onClick={() => handleDropdownToggle("category")}
								>
									<Dropdown.Toggle
										as={Button}
										variant="link"
										className="nav_link"
									>
										<PiStackPlusFill aria-label="Categories" />
										<span>Category</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown_menu">
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/add-category"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("category")}
										>
											<RxDotFilled />
											Add
										</Dropdown.Item>
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/category-list"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("category")}
										>
											<RxDotFilled />
											List
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
							<li>
								<Dropdown
									show={activeDropdown === "subcategory"}
									onClick={() => handleDropdownToggle("subcategory")}
								>
									<Dropdown.Toggle
										as={Button}
										variant="link"
										className="nav_link"
									>
										<IoCartSharp aria-label="Sub Category" />
										<span>Sub Category</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown_menu">
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/add-subcategory"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("subcategory")}
										>
											<RxDotFilled />
											Add
										</Dropdown.Item>
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/subcategory-list"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("subcategory")}
										>
											<RxDotFilled />
											List
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
							<li>
								<Dropdown
									show={activeDropdown === "product"}
									onClick={() => handleDropdownToggle("product")}
								>
									<Dropdown.Toggle
										as={Button}
										variant="link"
										className="nav_link"
									>
										<IoCartSharp aria-label="Product" />
										<span>Product</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown_menu">
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/add-product"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("subcategory")}
										>
											<RxDotFilled />
											Add
										</Dropdown.Item>
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/product-list"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("product")}
										>
											<RxDotFilled />
											List
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
							<li>
								<Dropdown
									show={activeDropdown === "user"}
									onClick={() => handleDropdownToggle("user")}
								>
									<Dropdown.Toggle
										as={Button}
										variant="link"
										className="nav_link"
									>
										<FaUserGroup aria-label="User" />
										<span>User</span>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown_menu">
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/partner"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("user")}
										>
											<RxDotFilled />
											Partner
										</Dropdown.Item>
										<Dropdown.Item
											as={NavLink}
											to="/dashboard/customer"
											className={({ isActive }) =>
												isActive ? "active dropdown_item" : "dropdown_item"
											}
											onClick={() => handleDropdownToggle("user")}
										>
											<RxDotFilled />
											Customer
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
							<li>
								<NavLink
									to="/dashboard/policy"
									className={({ isActive }) =>
										isActive ? "active" : undefined
									}
								>
									<MdPolicy />
									<span>Policy</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/dashboard/help-center"
									className={({ isActive }) =>
										isActive ? "active" : undefined
									}
								>
									<MdHelpCenter />
									<span>Help Center</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/dashboard/faqs"
									className={({ isActive }) =>
										isActive ? "active" : undefined
									}
								>
									<IoMdListBox />
									<span>FAQs</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/dashboard/settings"
									className={({ isActive }) =>
										isActive ? "active" : undefined
									}
								>
									<IoSettings />
									<span>Settings</span>
								</NavLink>
							</li>
						</ul>
					</div>
				</aside>
			</div>
		</>
	);
};

export default SideBar;
